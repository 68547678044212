import { ChainId, CurrencyAmount, Token } from '@baseswapfi/sdk-core';


// this was what was in chains.ts 

export enum UniverseChainId {
  // Mainnet = ChainId.MAINNET,
  // Goerli = ChainId.GOERLI,
  // Sepolia = ChainId.SEPOLIA,
  Optimism = ChainId.OPTIMISM,
  //OptimismGoerli = ChainId.OPTIMISM_GOERLI,
  ArbitrumOne = ChainId.ARBITRUM,
  // ArbitrumGoerli = ChainId.ARBITRUM_GOERLI,
  // Polygon = ChainId.POLYGON,
  // PolygonMumbai = ChainId.POLYGON_MUMBAI,
  // Avalanche = ChainId.AVALANCHE,
  // Celo = ChainId.CELO,
  // CeloAlfajores = ChainId.CELO_ALFAJORES,
  // Bnb = ChainId.BNB,
  Base = ChainId.BASE,
  // Base_Goerli = ChainId.BASE_GOERLI,
  // Blast = ChainId.BLAST,
  // Zora = ChainId.ZORA,
  // Zksync = ChainId.ZKSYNC,
}


