'use client';
import { useEffect, useState } from 'react';
import { ModeNetworkConfig } from '../config/mode-config';
import { AllNetworkConfigs } from '../config/network-config';
import { BaseNetworkConfig } from '../config/base-config';
import { OptimismNetworkConfig } from '../config/optimism-config';
import { isSupportedChain } from '../util/chains';
import { useChainId } from 'wagmi';
import { NetworkConfig } from '../config/network-config-type';
import { CHAIN_MAIN } from '~/constants';

const getNetworkConfig = (chainId: number) => {
  switch (chainId) {
    case 34443:
      return ModeNetworkConfig;
    case 8453:
      return BaseNetworkConfig;
    case 10:
      return OptimismNetworkConfig;
    default:
      throw new Error(`Unsupported chain ID: ${chainId}`);
  }
};

function useNetworkConfig() {
  const networkConfig = getNetworkConfig(CHAIN_MAIN);
  // const [networkConfig, setNetworkConfig] = useState<NetworkConfig>(BaseNetworkConfig);
  // const chainId = useChainId();

  // useEffect(() => {
  //   if (isSupportedChain(chainId)) setNetworkConfig(AllNetworkConfigs[chainId]);
  // }, [chainId]);

  return networkConfig;
}

export default useNetworkConfig;
